.list-checked {
  padding-left: 0px;
  list-style: none;
}
.list-checked-item {
  position: relative;
  display: block;
  color: #677788;
  padding-left: 1.75rem;
  margin-bottom: 30px;
  list-style: none;
}

.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background-image: url(../images/checklist.svg);
  content: '';
  margin-top: 0.125rem;
}

.sideContent * {
  color: #4f5d75 !important;
}
